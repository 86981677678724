<template>
    <div class="jh-dialog-wrap">
        <div class="jh-dialog-header">
            <h1>금칙어 신규등록</h1>
            <div class="is-right">
                <v-btn class="jh-btn is-icon" title="닫기" @click="$emit('hide')"><i class="jh-icon-close-lg is-white"></i></v-btn>
            </div>
        </div>
        <div class="jh-dialog-body">            
            <v-form ref="form" v-model="isFormValid" lazy-validation>
                <table class="jh-tbl-detail">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th><label class="is-required">금칙어</label></th>
                            <td>
                                <v-text-field class="jh-form is-md" v-model="formWordData.NEW_PROH_WORD" :rules="[formRules.newWord.required]" maxlength="100" outlined required></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <th><label class="is-required">변환어</label></th>
                            <td>
                                <v-text-field class="jh-form is-md" v-model="formWordData.NEW_CHANGE_WORD" :rules="[formRules.chWord.required]" maxlength="100" outlined required></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <th><label class="is-required">사용여부</label></th>
                            <td>
                                <v-select  
                                    class="jh-form is-md"
                                    :items="mixin_common_code_get(this.common_code, 'TWB001')" 
                                    v-model="formWordData.NEW_USE_YN"
                                    :rules="[formRules.useYn.required]"
                                    outlined required
                                ></v-select>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </v-form>
        </div>
        <div class="jh-dialog-footer" v-if="this.mixin_set_btn(this.$options.name, 'btnSave')">
            <v-btn class="jh-btn is-md is-main" @click="submit">저장</v-btn>
        </div>
    </div>
</template>

<script>
import api from "../../store/apiUtil";
import { mixin } from "@/mixin/mixin.js";
import { mapGetters } from "vuex";

let jsonObj

export default {
  name: "MENU_E020603P01", //name은 'MENU_' + 파일명 조합
  mixins:[mixin],
  props: {
    searchCustKey: {
      type: String,
      default: ''
    },
  },
  data(){
    return {
      common_code: [],
      mainVo: function() {
        this.NEW_PROH_WORD=''
        this.NEW_CHANGE_WORD=''
        this.NEW_USE_YN=''
        this.ASP_NEWCUST_KEY=''
      },
      formWordData: {},
      isFormValid: true,
      formRulesSubfix: ' 은(는) 필수입력입니다.',
      formRules: {
        newWord: {
          required: v => !!v || '금칙어' + this.formRulesSubfix
        },
        chWord: {
          required: v => !!(v  && (v+'').trim()!=='') || '변환어' + this.formRulesSubfix
        },
        useYn: {
          required: v => !!(v  && (v+'').trim()!=='') || '사용여부' + this.formRulesSubfix
        }
      },
    }
  },
  mounted() {
    this.initData()
  },
  methods:{
    async initData(){
      this.$refs.form.resetValidation()
      this.formWordData = new this.mainVo()

      let codeName = ["TWB001"];
      this.common_code= await this.mixin_common_code_get_all(codeName);
    },
    async submit(){

      if(!this.$refs.form.validate()) return false

      let URLData = "/api/chat/setting/banned-word/regist";
      let HEADER_SERVICE = "chat.setting.banned-word";
      let HEADER_METHOD = "regist";
      let HEADER_TYPE = "BIZ_SERVICE";

      let jsonData = {
        ASP_NEWCUST_KEY: this.searchCustKey,
        NEW_PROH_WORD: this.formWordData.NEW_PROH_WORD,
        NEW_CHANGE_WORD: this.formWordData.NEW_CHANGE_WORD,
        USE_YN: this.formWordData.NEW_USE_YN,
        USER_NM: this.$store.getters['userStore/GE_USER_NAME'],
        USER_ID: this.$store.getters['userStore/GE_USER_ID']
      };

      //api 호출 부분
      await api
          .post(URLData, jsonData, {
            head: {
              SERVICE: HEADER_SERVICE,
              METHOD: HEADER_METHOD,
              TYPE: HEADER_TYPE
            },
          })
          .then((response) => {
            if(!this.isEmpty(response) && !response.data.HEADER.ERROR_FLAG) {
              this.common_alert('정상처리 되었습니다.', 'done')
              this.$emit('hideAndGetList');
            } else {
              this.common_alert(response.data.HEADER.ERROR_MSG, 'error')
            }
          })
          .catch((err) => {
            alert(err);
          });
    },
    isEmpty(val) {
      if (val == null) return true
      if (typeof val === 'undefined') return true
      if (typeof val === 'string' && val === '' && (''+val) === '') return true
      if (Array.isArray(val) && val.length < 1) return true
      if (typeof val === 'object' && val.constructor.name === 'Object' && Object.keys(val).length < 1 && Object.getOwnPropertyNames(val) < 1) return true
      if (typeof val === 'object' && val.constructor.name === 'String' && Object.keys(val).length < 1) return true
    },
  }
};
</script>

<style></style>
